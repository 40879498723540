<template>
  <section>
    <div
      class="card"
      :class="{ 'card-as-tab': cardAsTab }"
    >
      <div
        v-if="loading"
        class="progress-loading"
      >
        <b-progress
          :value="100"
          height="5px"
          variant="primary"
          :animated="true"
        />
      </div>
      <div class="card-header bg-white">
        <div class="row my-2 align-items-center">
          <div class="col-auto">
            <slot name="header-button" />
          </div>
          <div class="col">
            <slot name="nav-pills" />
          </div>
          <div class="col-auto pr-0">
            <i
              v-if="loading"
              class="fas fa-circle-notch fa-spin mr-2"
            />
          </div>
          <div
            v-if="selectedMutlipleAction.length !== 0"
            class="col-auto"
          >
            <b-button
              variant="danger"
              @click="deleteMutipleActions"
            >
              {{ selectedMutlipleAction.length }} Fahrzeug{{ selectedMutlipleAction.length === 1 ? '' : 'e' }} löschen
            </b-button>
          </div>
          <div
            v-if="$slots.customSearch"
            class="col-auto pl-0"
          >
            <slot name="customSearch" />
          </div>

          <div
            v-if="searchField"
            class="col-3 pl-0"
          >
            <basic-input
              v-model="search"
              placeholder="Suchen"
              :margin="false"
              type="search"
            >
              <template #append>
                <button
                  type="button"
                  class="btn btn-outline-gray-3 text-gray-5"
                >
                  <i class="far fa-search" />
                </button>
              </template>
            </basic-input>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <b-table
          ref="data-table"
          :items="collection.data"
          :fields="totalFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="data-table"
          striped
          no-local-sorting
          hover
          @row-clicked="item => $emit('row-clicked', item)"
        >
          <template slot="HEAD_DATATABLE_SELECTION">
            Löschen
          </template>
          <template
            slot="DATATABLE_SELECTION"
            slot-scope="data"
          >
            <b-form-checkbox
              :id="'checkbox' + data.item._id"
              :checked="isDeletedChecked(data.item._id)"
              @input="setDeletedChecked(data.item._id, $event)"
            />
          </template>
          <template
            v-for="field in fields"
            :slot="'HEAD_'+field.key"
            slot-scope="slotData"
          >
            {{ slotData.label }}
            <filter-wrapper
              v-if="slotData.field.filter && slotData.field.filter.type"
              :key="field.key"
              :field="field"
              @query-change="handleQueryChange"
            />
          </template>
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="'cell('+ name +')'"
            slot-scope="slotData"
          >
            <slot
              :name="name"
              v-bind="slotData"
            />
          </template>
        </b-table>
      </div>
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col">
            <span
              v-if="collection.total"
              class="small muded"
            >{{ collection.total }} Einträge auf {{ pages }} Seiten</span>
          </div>
          <div class="col-auto">
            <b-pagination
              v-model="page"
              :total-rows="collection.total"
              :per-page="collection.limit"
              class="mb-0 pagination"
            >
              <template slot="first-text">
                <i class="far fa-angle-double-left" />
              </template>
              <template slot="prev-text">
                <i class="far fa-angle-left" />
              </template>
              <template slot="next-text">
                <i class="far fa-angle-right" />
              </template>
              <template slot="last-text">
                <i class="far fa-angle-double-right" />
              </template>
              <template slot="ellipsis-text">
                <i class="far fa-ellipsis-h" />
              </template>
            </b-pagination>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end align-items-center">
              <i
                v-if="loading"
                class="fas fa-circle-notch fa-spin mr-2"
              />
              Einträge pro Seite:
              <basic-select
                v-model="limit"
                class="ml-2"
                :margin="false"
              >
                <option :value="15">
                  15
                </option>
                <option :value="20">
                  20
                </option>
                <option :value="30">
                  30
                </option>
                <option :value="40">
                  40
                </option>
                <option :value="50">
                  50
                </option>
              </basic-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicInput from '@/components/BaseComponents/BasicInput'
import FilterWrapper from './Filters/FilterWrapper'
import mergeQueries from './Filters/mergeQueries'
import debounce from 'lodash.debounce'

export default {
  name: 'DataTable',
  components: {
    BasicSelect,
    BasicInput,
    FilterWrapper
  },
  props: {
    service: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    fields: {
      type: Array,
      required: true
    },
    baseQuery: {
      type: Object,
      default: () => { }
    },
    multipleDelete: {
      type: Boolean,
      default: false
    },
    cardAsTab: {
      type: Boolean,
      default: false
    },
    searchField: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    error: null,
    loading: false,
    collection: { data: [] },
    filterQueries: {},
    selectedMutlipleAction: [],
    curFetchDataRequestSymbol: null,
    debouncedFetchData: debounce(async function () {
      await this.fetchData()
    }, 200)
  }),
  computed: {
    isDeletedChecked () {
      return (id) => this.selectedMutlipleAction.indexOf(id) !== -1
    },
    totalFields () {
      if (this.multipleDelete) {
        return [
          {
            label: '',
            key: 'DATATABLE_SELECTION'
          },
          ...this.fields
        ]
      } else {
        return [...this.fields]
      }
    },
    pages () {
      return Math.ceil(this.collection.total / this.collection.limit)
    },
    page: {
      get () {
        return Number.parseInt(this.$route.query.page) || 1
      },
      set (page) {
        this.$router.push({ query: { ...this.$route.query, page, limit: this.collection.limit } })
      }
    },
    limit: {
      get () {
        return this.$route.query.limit || 15
      },
      set (limit) {
        this.$router.replace({ query: { ...this.$route.query, page: '1', limit } })
      }
    },
    sortBy: {
      get () {
        return this.$route.query.sortBy
      },
      set (sortBy) {
        this.$router.replace({ query: { ...this.$route.query, sortBy, limit: this.collection.limit } })
      }
    },
    sortDesc: {
      get () {
        return this.$route.query.sortDesc === 'true' || this.$route.query.sortDesc === true
      },
      set (sortDesc) {
        this.$router.replace({ query: { ...this.$route.query, sortDesc, limit: this.collection.limit } })
      }
    },
    search: {
      get () {
        return this.$route.query.search
      },
      set (search) {
        if (search === this.$route.query.search) return
        this.$router.replace({ query: { ...this.$route.query, search, limit: this.collection.limit } })
      }
    },
    query () {
      let query = {
        $skip: (this.page - 1) * this.limit,
        $sort: { createdAt: -1 },
        $limit: this.limit,
        ...this.baseQuery
      }
      query = mergeQueries(query, ...Object.values(this.filterQueries))
      if (this.sortBy) {
        query.$sort = { [this.sortBy]: this.sortDesc ? -1 : 1 }
      }
      if (this.search) {
        query.$search = this.search
      }
      return query
    }
  },
  watch: {
    query: {
      handler: 'debouncedFetchData',
      immediate: true
    }
  },
  methods: {
    async deleteMutipleActions () {
      try {
        for (let i = 0; i < this.selectedMutlipleAction.length; i++) {
          await this.$store.dispatch(`${this.service}/remove`, this.selectedMutlipleAction[i])
        }
        this.selectedMutlipleAction = []
        this.$emit('deleted')
        await this.fetchData()
      } catch (error) {
        console.error(error)
      }
    },
    setDeletedChecked (id, state) {
      if (state && this.selectedMutlipleAction.indexOf(id) === -1) {
        this.selectedMutlipleAction.push(id)
      } else if (!state && this.selectedMutlipleAction.indexOf(id) !== -1) {
        this.selectedMutlipleAction.splice(this.selectedMutlipleAction.indexOf(id), 1)
      }
    },
    handleQueryChange (event) {
      this.$set(this.filterQueries, event.key, event.query)
    },
    async fetchData () {
      const loadingTimeout = setTimeout(() => {
        this.loading = true
      }, 300)
      this.$emit('loading', this.loading)
      try {
        const fetchSymbol = Symbol('fetchSymbol')
        this.curFetchDataRequestSymbol = fetchSymbol
        const collectionReq = await this.$store.dispatch(`${this.service}/find`, {
          query: this.query
        })
        // check if the requested data is stale
        if (this.curFetchDataRequestSymbol === fetchSymbol) {
          this.collection = collectionReq
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
        clearTimeout(loadingTimeout)
        this.$emit('loading', this.loading)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.progress-loading {
  position: absolute;
  top: 0;
  height: 5px;
  left: 0;
  right: 0;
}
.data-table ::v-deep {
  & tr:hover {
    cursor: pointer;
  }
  td {
    vertical-align: middle !important;
  }
  thead th {
    border: none;
    padding-bottom: 5px;
  }
  & > tfoot > tr > [aria-sort],
  & > thead > tr > [aria-sort] {
    cursor: pointer;
    background-image: none;
    background-repeat: no-repeat;
    background-size: 0.65em 1em;
  }
  & > tfoot > tr > [aria-sort],
  & > thead > tr > [aria-sort] {
    background-position: right 0.375rem center;
    padding-right: calc(0.75rem + 0.65em);
  }
  & > tfoot > tr > [aria-sort="descending"],
  & > thead > tr > [aria-sort="descending"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
  }
  & > tfoot > tr > [aria-sort="ascending"],
  & > thead > tr > [aria-sort="ascending"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
  }
  & > tfoot > tr > [aria-sort="none"],
  & > thead > tr > [aria-sort="none"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
  }
}
.card-as-tab {
  border: none;
}
</style>
