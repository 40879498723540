<template>
  <span v-if="filterComponent">
    <i
      :id="field.key+'-target'"
      :class="{ 'fas': isFilterApplied, 'far': !isFilterApplied }"
      class="fa-filter"
      tabindex="0"
    />
    <b-popover
      :show.sync="opened"
      :target="field.key+'-target'"
      triggers="click blur"
      placement="bottom"
    >
      <b-button
        class="close"
        @click="opened = false"
      >
        <i class="fal fa-times" />
      </b-button>

      <component
        :is="filterComponent"
        ref="filterComponent"
        :field="field"
        :opened="opened"
        @change="filterChange"
      />

      <b-button
        size="sm"
        variant="primary"
        class="p-0"
        block
        @click="deleteFilter()"
      >
        löschen
      </b-button>
    </b-popover>
  </span>
</template>

<script>

export default {
  name: 'FilterWrapper',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      opened: false,
      isFilterApplied: false,
      delete: false,
      loadingComponent: true,
      filterComponent: null
    }
  },
  watch: {
    'field.filter.type': {
      handler: 'loadFilterComponent',
      immediate: true
    }
  },
  methods: {
    filterChange (event) {
      this.isFilterApplied = event.isApplied
      this.$emit('query-change', { query: event.query, key: this.field.key })
    },
    deleteFilter () {
      this.$refs.filterComponent.deleteFilter()
    },
    async loadFilterComponent (type) {
      try {
        this.loadingComponent = true
        if (type) this.filterComponent = (await import('./Filter' + type + '.vue')).default
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingComponent = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
