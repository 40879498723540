export default function (...queries) {
  if (queries.length < 2) {
    return queries[0]
  }
  const targetQuery = { ...queries[0] }
  for (let i = 1; i < queries.length; i++) {
    const mergeQuery = queries[i]

    if (targetQuery.$and && !targetQuery.$or && mergeQuery.$and) { // ANDs
      targetQuery.$and = [
        ...targetQuery.$and,
        ...mergeQuery.$and
      ]
    } else if (targetQuery.$and && targetQuery.$or && mergeQuery.$and) {
      targetQuery.$and = [
        ...targetQuery.$and,
        ...mergeQuery.$and
      ]
    } else if (!targetQuery.$and && targetQuery.$or && mergeQuery.$and) {
      targetQuery.$and = mergeQuery.$and
    } else if (!targetQuery.$and && !targetQuery.$or && mergeQuery.$and) {
      targetQuery.$and = mergeQuery.$and
    } else if (targetQuery.$and && !targetQuery.$or && mergeQuery.$or) { // ORs
      targetQuery.$or = mergeQuery.$or
    } else if (targetQuery.$and && targetQuery.$or && mergeQuery.$or) {
      targetQuery.$and = [
        ...targetQuery.$and,
        targetQuery.$or,
        mergeQuery.$or
      ]
    } else if (!targetQuery.$and && targetQuery.$or && mergeQuery.$or) {
      targetQuery.$and = [
        targetQuery.$or,
        mergeQuery.$or
      ]
    } else if (!targetQuery.$and && !targetQuery.$or && mergeQuery.$or) {
      targetQuery.$or = mergeQuery.$or
    }
  }
  return targetQuery
}
